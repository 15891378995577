export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"},{"name":"cache-control","content":"no-cache, no-store, must-revalidate"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap"},{"rel":"apple-touch-icon","type":"image/png","href":"/icon_shortcut_180.png"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"//polyfill.io/v3/polyfill.min.js?features=IntersectionObserver,ResizeObserver,WebAnimations,Object.fromEntries,Array.prototype.at"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"